import React from "react";
import { Helmet } from "react-helmet";
import WithPrivacyPolicyPG from "../pagesComponents/WithPrivacyPolicyPG";

const PrivacyPolicyEs = () => (
  <>
    <Helmet
      meta={[
        {
          name: "robots",
          content: "noindex, nofollow, noarchive, noimageindex",
        },
        {
          name: "googlebot",
          content: "noindex, nofollow, noarchive, noimageindex",
        },
      ]}
    />
    <h2>POLÍTICA DE PRIVACIDAD</h2>
    <h2>DECLARACIÓN DE PRIVACIDAD</h2>
    <h2>SECCIÓN 1 &#8211; ¿QUÉ HACEMOS CON SU INFORMACIÓN?</h2>
    <p>Cuando compra algo en nuestra tienda, como parte del proceso de compra y venta, recopilamos la información personal que nos proporciona, como su nombre, dirección y dirección de correo electrónico.</p>
    <p>Cuando navega por nuestra tienda, recibimos automáticamente la dirección de protocolo de Internet (IP) de su ordenador, que nos proporciona información que nos permite conocer su navegador y su sistema operativo.</p>
    <h2>SECCIÓN 2 &#8211; CONSENTIMIENTO</h2>
    <p>Cuando nos proporciona información personal para completar una transacción, realizar un pedido, organizar una entrega o devolver una compra, implicamos que nos da su consentimiento para ponernos en contacto con usted.</p>
    <p>Si no deseas una ventaja injusta recibiendo consejos, información sobre nuevos productos y otras actualizaciones que puedan beneficiarte, puedes darte de baja de correos no transaccionales haciendo clic en el enlace para darse de baja que aparece en ellos.</p>
    <h2>SECCIÓN 3 &#8211; DIVULGACIÓN</h2>
    <p>Podemos revelar su información personal si la ley nos obliga a ello o si usted infringe nuestras Condiciones de servicio.</p>
    <h2>SECCIÓN 4 &#8211; SERVICIOS DE TERCEROS</h2>
    <p>En general, los proveedores externos que utilizamos sólo recopilarán, utilizarán y divulgarán su información en la medida necesaria para permitirles realizar los servicios que nos prestan.</p>
    <p>Sin embargo, algunos proveedores de servicios externos, como las pasarelas de pago y otros procesadores de transacciones de pago, tienen sus propias políticas de privacidad con respecto a la información que estamos obligados a proporcionarles para sus transacciones relacionadas con compras.</p>
    <p>Para estos proveedores, le recomendamos que lea sus políticas de privacidad para que pueda entender la forma en que su información personal será tratada por estos proveedores.</p>
    <p>En particular, recuerde que ciertos proveedores pueden estar ubicados o tener instalaciones ubicadas en una jurisdicción diferente a la suya o a la nuestra. Por lo tanto, si decide realizar una transacción que implique los servicios de un proveedor de servicios externo, su información puede quedar sujeta a las leyes de la jurisdicción o jurisdicciones en las que se encuentre dicho proveedor de servicios o sus instalaciones.</p>
    <p>A modo de ejemplo, si usted se encuentra en Canadá y su transacción es procesada por una pasarela de pago ubicada en los Estados Unidos, entonces su información personal utilizada para completar esa transacción puede estar sujeta a divulgación en virtud de la legislación de los Estados Unidos, incluida la Ley Patriota.</p>
    <p>Cuando haga clic en los enlaces de nuestra tienda, es posible que le dirijan fuera de nuestro sitio. No nos hacemos responsables de las prácticas de privacidad de otros sitios y le recomendamos que lea sus declaraciones de privacidad.</p>
    <p>Digistore24 es nuestro distribuidor y recibe los pagos en nuestro nombre. Nunca tenemos acceso a la información de su tarjeta de crédito.</p>
    <p><a href="https://www.digistore24.com/info/privacy/2">Política de privacidad de Digistore24</a></p>
    <p>Una vez que abandone el sitio web de nuestra tienda o sea redirigido a un sitio web o aplicación de terceros, dejará de regirse por la presente Política de privacidad.</p>
    <h2>SECCIÓN 5 &#8211; SEGURIDAD</h2>
    <p>Para proteger su información personal, tomamos precauciones razonables y seguimos las mejores prácticas de la industria para asegurarnos de que de forma inapropiada no se pierda, se utilice indebidamente, se acceda a ella, se divulgue, se altere o se destruya.</p>
  </>
);

export default WithPrivacyPolicyPG(PrivacyPolicyEs);